import React from "react"
import Layout from "../../components/Layouts/uploadLayout"
import { ThreadsWidget } from "../../components/Upload/ThreadsWidget"

export default function UploadingDocuments() {
  return (
    <Layout
      title="Специальное предложение от Совкомбанка"
      showExitButton
      anotherBackgroundColor="standard"
    >
      <ThreadsWidget />
    </Layout>
  )
}
